import { TFunction } from 'next-i18next';

import drawingGridExample from '../assets/images/grid.png';
import gridResponseExample from '../assets/images/gridResponseExample.png';
import podiumExample from '../assets/images/podium.png';
import pollExample from '../assets/images/poll.png';
import textGridExample from '../assets/images/textgrid.png';
import wordcloudExample from '../assets/images/wordcloudexample.png';
import theme from '../assets/theme';
import {
  Round,
  RoundOptions,
  RoundType,
  SlideType,
} from '../services/backendService/types';
import { findRelatedRoundsWithoutIndex } from './roundUtils';

export const toSubjects = (t: TFunction, type?: string | RoundType, plural?: boolean) => {
  if (type === 'questions') return t(`question${plural ? 's' : ''}`); /** @depricated */
  if (type === 'answers') return t(`answer${plural ? 's' : ''}`);
  if (type === 'aiAssessedAnswers') return t(`answer${plural ? 's' : ''}`);
  if (type === 'aiAssessedImageAnswers') return t(`drawing${plural ? 's' : ''}`);
  if (type === 'wordcloud') return t(`word${plural ? 's' : ''}`);
  if (type === 'ideas') return t(`idea${plural ? 's' : ''}`);
  if (type === 'canvas') return t(`drawing${plural ? 's' : ''}`);
  if (type === 'poll') return t(`vote${plural ? 's' : ''}`);
  if (type === 'voting') return t(`vote${plural ? 's' : ''}`);
  if (type === 'videos') return t(`video${plural ? 's' : ''}`); /** @depricated */
  return '';
};

export const typeToActivityMap = (type: RoundType | SlideType | string) => {
  if (type === 'slide') {
    return {
      title: 'Slide',
      paragraph: '',
      image: null,
      icon: 'TitleImageSvg',
    };
  }

  if (type === 'header') {
    return {
      title: 'Header',
      // paragraph: 'Enkelt lysbilde med støtte for tittel og undertittel.',
      paragraph: 'Simple slide with support for a title and a subtitle',
      image: null,
      icon: 'SlideHeaderSvg',
    };
  }
  if (type === 'header_text' || type === 'bullets') {
    return {
      title: 'Text',
      // paragraph: 'Enkelt lysbilde med støtte for tittel og undertittel.',
      paragraph: 'Simple slide with support for a title and text',
      image: null,
      icon: 'SlideHeaderText',
    };
  }

  if (type === 'image') {
    return {
      title: 'Title & media',
      // paragraph:
      //   'Lysbilde for media med støtte for tittel. Legg til bilder, GIFs, og youtube-videoer',
      paragraph: `Slide with media and a title. Add images, gif's and youtube videos`,
      image: null,
      icon: 'TitleImageSvg',
    };
  }
  if (type === 'image_text') {
    return {
      title: 'Media & text',
      // paragraph:
      //   'Lysbilde for media med støtte for tittel. Legg til bilder, GIFs, og youtube-videoer',
      paragraph: `Slide with media, text and a title. Add images, gif's and youtube videos`,
      image: null,
      icon: 'MediaTextSvg',
    };
  }
  if (type === 'text_image') {
    return {
      title: 'Text & media',
      // paragraph:
      //   'Lysbilde for media med støtte for tittel. Legg til bilder, GIFs, og youtube-videoer',
      paragraph: `Slide with media, text and a title. Add images, gif's and youtube videos`,
      image: null,
      icon: 'TextMediaSvg',
    };
  }
  if (type === 'fullscreen_media') {
    return {
      title: 'Fullscreen media',
      // paragraph:
      //   'Lysbilde for media som skal være i fullskjerm. Bilder, GIFs og youtube-videoer er støttet',
      paragraph: 'Slide for a full screen media. Add an image, GIF or youtube videos',
      image: null,
      icon: 'YoutubeSvg',
    };
  }
  if (type === 'poll') {
    return {
      title: 'Poll',
      // paragraph:
      //   'Deltagerne velger blant opp til 10 valg. Resultatene blir vist på storskjermen',
      paragraph:
        'Your participants choose between up to 10 options. The results are shown on the big screen.',
      image: pollExample,
      icon: 'PollIcon',
    };
  }
  if (type === 'videos') {
    /** @depricated */
    return {
      title: 'Video response',
      // paragraph:
      //   'Deltagerne svarer med korte videosnutter laget med et valgfritt videoredigeringsverktøy',
      paragraph:
        'Your participants respond with a very short video (15 seconds) made with their favourite video editing tool. The results will be displayed in a grid.',
      image: gridResponseExample,
      alternateImage: textGridExample,
      icon: 'VideoActivitySvg',
      beta: true,
    };
  }
  // TODO: Groupmaker experience
  if (type === 'groupmaker') {
    return {
      title: 'Group',
      paragraph: 'Create groups',
      image: null,
      icon: 'GroupSvg',
    };
  }

  if (type === 'wordcloud') {
    return {
      title: 'Word cloud',
      // paragraph:
      //   'Deltagerne skriver inn korte svar på maks 30 tegn. Resultatene vises som en ordsky på storskjermen.',
      paragraph:
        'Your participants write short answers for up to 30 characters. The results are shown as a word cloud on the big screen.',
      image: wordcloudExample.src,
      icon: 'CloudIcon',
    };
  }
  if (type === 'answers') {
    return {
      title: 'Open question',
      // paragraph:
      //   'Deltagerne svarer på spørsmålet ditt med lengre setninger. Svarene kan vises i et rutenett eller legg til avstemming for å gjøre det til en konkurranse!',
      paragraph:
        'Your participants answer your question with longer sentences. Participants can vote for the best answers and the results are displayed as a podium.',
      alternateParagraph:
        'Your participants answer your question with longer sentences. The results will be displayed in a grid',
      image: podiumExample,
      alternateImage: textGridExample,
      icon: 'QuestionsIcon',
    };
  }
  if (type === 'aiAssessedAnswers') {
    return {
      title: 'AI Feedback',
      // paragraph:
      //   'Deltagerne svarer på spørsmålet ditt med lengre setninger. Svarene kan vises i et rutenett eller legg til avstemming for å gjøre det til en konkurranse!',
      paragraph:
        'Your participants answer your question with longer sentences. Choose between a maximum character limit of either 1000 or 2300. You will get the chance to instantly assess the responses with the help of AI.',
      alternateParagraph:
        'Your participants answer your question with longer sentences. Choose between a maximum character limit of either 1000 or 2300. The results will be displayed in a grid. ',
      image: gridResponseExample,
      alternateImage: textGridExample,
      icon: 'InstantFeedbackIcon',
      beta: false,
    };
  }
  if (type === 'aiAssessedImageAnswers') {
    return {
      title: 'AI Whiteboard',
      // paragraph:
      //   'Deltagerne svarer på spørsmålet ditt med lengre setninger. Svarene kan vises i et rutenett eller legg til avstemming for å gjøre det til en konkurranse!',
      paragraph:
        'Your students answer with a drawing. You will get the chance to instantly assess the responses with the help of AI.',
      alternateParagraph:
        'Your students answer with a drawing. You will get the chance to instantly assess the responses with the help of AI.',
      image: gridResponseExample,
      alternateImage: textGridExample,
      icon: 'InstantDrawingFeedbackIcon',
      beta: false,
    };
  }
  if (type === 'canvas') {
    return {
      title: 'Drawings',
      // paragraph:
      //   'Deltagerne tegner i en fri tavle, løser problemer og svarer på spørsmål. Tegn, reflekter og se svarene i et rutenett eller legg til avstemning for å gjøre det til en konkurranse!',
      paragraph:
        'Your participants answer with a drawing. The results will be displayed as a podium.',
      alternateParagraph:
        'Your participants answer with a drawing. The results are displayed in a grid.',
      image: podiumExample,
      alternateImage: drawingGridExample,
      icon: 'DrawingsIcon',
    };
  }
  if (type === 'questions') {
    /** @depricated */
    return {
      title: 'Q&A',
      // paragraph:
      //   'Deltagerne stiller spørsmål til deg. Ta inn spørsmål på slutten av et foredrag eller tren på å stille gode spørsmål. Legg til avstemming for å demokratisk finne de beste spørsmålene som flest ønsker svar på.',
      paragraph:
        'Run a Q&A session where everyone gets the chance to ask you a question. Participants can vote for the best questions and the results will be displayed as a podium.',
      alternateParagraph:
        'Your participants ask you questions. All the questions will be displayed in a grid.',
      image: podiumExample,
      alternateImage: textGridExample,
      icon: 'FlashIcon',
    };
  }

  return null;
};

export function generateUniqueId() {
  return Math.floor(Math.random() * Date.now());
}
const INDEX_COLORS = [
  theme.colors.orange,
  theme.colors.green,
  theme.colors.red,
  theme.colors.blue,
  theme.colors.yellow,
  theme.colors.blueDark,
  theme.colors.aqua,
  theme.colors.black,
  theme.colors.eraserPink,
  theme.colors.purple,
];
export const getIndexToColor = (index?: number) => {
  if (index === undefined) return '';
  if (index > INDEX_COLORS.length - 1) return INDEX_COLORS[0];
  return INDEX_COLORS[index];
};
export const generateRoundOptionColor = (existingColors: string[]) => {
  const availableColors = INDEX_COLORS.filter(color => !existingColors.includes(color));

  if (availableColors.length === 0) {
    throw new Error('Not any more colors');
  }
  return availableColors[0];
};

export const generateDefaultRoundOptions = (count: number) => {
  return new Array(count)
    .fill(0)
    .reduce<string[]>(acc => [...acc, generateRoundOptionColor(acc)], [])
    .map(
      color =>
        ({
          id: generateUniqueId().toString(),
          displayName: ``,
          color,
        } as RoundOptions),
    );
};

export function getInsertIndex(allRounds: Round[], roundId?: string) {
  // returns the index where related rounds should be insterted into the flat round array
  if (!roundId) {
    return allRounds.length > 0 ? allRounds.length : 0;
  }
  const relatedRounds = findRelatedRoundsWithoutIndex(roundId, allRounds);
  const lastRelatedRoundIndex = allRounds.findIndex(
    r => r.id === relatedRounds[relatedRounds.length - 1].id,
  );
  return lastRelatedRoundIndex + 1;
}

const insertRelatedRounds = (arr: Round[], index: number, ...newItems: Round[]) => [
  ...arr.slice(0, index),
  ...newItems,
  ...arr.slice(index),
];

export const insertRoundsAfterSelectedRound = (
  rounds: Round[],
  newRounds: Round[],
  selectedRoundId?: string,
): Round[] => {
  const insertIndex = getInsertIndex(rounds, selectedRoundId);
  return insertRelatedRounds(rounds, insertIndex, ...newRounds);
};

export const MOBILE_TO_DESKTOP_BREAKPOINT_PX = 900;
