import React from 'react';
import { TFunction } from 'react-i18next';
import { empty } from 'rxjs';

import AIImageFeedbackSvg from '../assets/icons/AIImageFeedbackSvg';
import BrainstormSvg from '../assets/icons/BrainstormSvg';
import { CloudSvg } from '../assets/icons/CloudSvg';
import DrawingsSvg from '../assets/icons/DrawingsSvg';
import FlashSvg from '../assets/icons/FlashSvg';
import GridSvg from '../assets/icons/GridSvg';
import GroupSvg from '../assets/icons/GroupSvg';
import InstantFeedbackSvg from '../assets/icons/InstantFeedbackSvg';
import LiveAnswerSvg from '../assets/icons/LiveAnswerSvg';
import PodiumSvg from '../assets/icons/PodiumSvg';
import { PollSvg } from '../assets/icons/PollSvg';
import QuestionsSvg from '../assets/icons/QuestionsSvg';
import SlideBulletsSvg from '../assets/icons/SlideBulletsSvg';
import SlideHeaderSvg from '../assets/icons/SlideHeaderSvg';
import { SlideHeaderText } from '../assets/icons/SlideHeaderText';
import { SlideImageTextSvg } from '../assets/icons/SlideImageTextSvg';
import { SlideTextImageSvg } from '../assets/icons/SlideTextImageSvg';
import TitleImageSvg from '../assets/icons/TitleImageSvg';
import VideoActivitySvg from '../assets/icons/VideoActivitySvg';
import VotingSvg from '../assets/icons/VotingSvg';
import YoutubeSvg from '../assets/icons/YoutubeSvg';
import theme from '../assets/theme';
import { Slide } from '../components/SlideComponents/slide.types';
import { findRelatedSlidesWithoutIndex } from '../components/SlideComponents/slideUtil';
import { newRelicNoticeError } from '../hooks/useNewRelic';
import {
  Round,
  RoundOptions,
  RoundType,
  SlideType,
} from '../services/backendService/types';
import { toSubjects } from './creator';

export const toIcon = (type: string): { icon: React.ReactNode } => {
  if (type === 'questions')
    /** @depricated */
    return {
      icon: <FlashSvg />,
    };
  if (type === 'answers')
    return {
      icon: <LiveAnswerSvg />,
    };
  if (type === 'aiAssessedImageAnswers')
    return {
      icon: <AIImageFeedbackSvg />,
    };
  if (type === 'aiAssessedAnswers')
    return {
      icon: <InstantFeedbackSvg />,
    };
  if (type === 'voting')
    return {
      icon: <VotingSvg />,
    };
  if (type === 'ideas')
    return {
      icon: <BrainstormSvg />,
    };
  if (type === 'wordcloud')
    return {
      icon: <CloudSvg />,
    };
  if (type === 'poll')
    return {
      icon: <PollSvg />,
    };
  if (type === 'header')
    return {
      icon: <SlideHeaderSvg />,
    };
  if (type === 'slide')
    return {
      icon: <SlideHeaderSvg />,
    };
  if (type === 'header_text' || type === 'bullets')
    return {
      icon: <SlideHeaderSvg />,
    };
  if (type === 'text_image')
    return {
      icon: <SlideTextImageSvg />,
    };
  if (type === 'image_text')
    return {
      icon: <SlideImageTextSvg />,
    };
  if (type === 'image')
    return {
      icon: <TitleImageSvg />,
    };
  if (type === 'fullscreen_media')
    return {
      icon: <YoutubeSvg />,
    };
  if (type === 'canvas')
    return {
      icon: <DrawingsSvg />,
    };
  if (type === 'responsegrid')
    return {
      icon: <GridSvg />,
    };
  if (type === 'podium')
    return {
      icon: <PodiumSvg />,
    };
  if (type === 'videos')
    /** @depricated */
    return {
      icon: <VideoActivitySvg />,
    };
  if (type === 'groupmaker')
    return {
      icon: <GroupSvg />,
    };

  return {
    icon: null,
  };
};

export const createRoundGroups = (rounds: Round[]) => {
  const reversed = [...rounds].reverse();
  const groups: Round[][] = [];
  const parsedRounds: string[] = [];
  reversed.forEach(round => {
    if (round.type === 'slide') {
      groups.push([round]);
      parsedRounds.push(round.id);
    } else {
      if (parsedRounds.includes(round.id)) return;
      const relatedRounds = listRoundsByReferences(round.id, rounds);
      relatedRounds.forEach(r => {
        parsedRounds.push(r.id);
      });
      groups.push(relatedRounds);
    }
  });
  return groups.reverse();
};

export const activityMapToIcon = (icon?: string | null) => {
  if (!icon || icon === null) return undefined;
  switch (icon) {
    case 'CloudIcon':
      return <CloudSvg />;
    case 'SlideHeaderSvg':
      return <SlideHeaderSvg />;
    case 'SlideBulletsSvg':
      return <SlideBulletsSvg />;
    case 'DrawingsIcon':
      return <DrawingsSvg />;
    case 'FlashIcon':
      return <FlashSvg />;
    case 'PollIcon':
      return <PollSvg />;
    case 'YoutubeSvg':
      return <YoutubeSvg />;
    case 'QuestionsIcon':
      return <QuestionsSvg />;
    case 'TitleImageSvg':
      return <TitleImageSvg />;
    case 'VideoActivitySvg':
      return <VideoActivitySvg />;
    case 'InstantFeedbackIcon':
      return <InstantFeedbackSvg />;
    case 'TextMediaSvg':
      return <SlideTextImageSvg />;
    case 'MediaTextSvg':
      return <SlideImageTextSvg />;
    case 'SlideHeaderText':
      return <SlideHeaderText />;
  }
};

export type RoundState = 'pre' | 'countdown' | 'in' | 'post' | 'post-between';

export const pollExampleData: RoundOptions[] = [
  {
    id: '0',
    displayName: 'Cycling',
    count: 3,
  },
  {
    id: '1',
    displayName: 'Hiking',
    count: 6,
  },
  {
    id: '2',
    displayName: 'Running',
    count: 4,
  },
  {
    id: '3',
    displayName: 'Swimming',
    count: 2,
  },
];

export const isSlideType = (type?: string) => {
  if (!type) return false;
  switch (type) {
    case 'fullscreen_media':
    case 'image':
    case 'image_text':
    case 'text_image':
    case 'bullets': /** @deprecated */
    case 'header':
    case 'header_text':
      return true;
    default:
      return false;
  }
};
export const getHasNextActivityOrSlide = (slides: Slide[], currentSlideId: string) => {
  if (slides.length === 0) return false;
  const relatedSlides = findRelatedSlidesWithoutIndex(currentSlideId, slides);
  const lastSlide = relatedSlides[relatedSlides.length - 1];
  const lastGameSlide = slides[slides.length - 1];

  return lastSlide.id !== lastGameSlide.id;
};

export const getHasPreviousActivityOrSlide = (
  slides: Slide[],
  currentSlideId: string,
) => {
  if (slides.length === 0) return false;
  const relatedSlides = findRelatedSlidesWithoutIndex(currentSlideId, slides);
  const firstSlides = relatedSlides[0];
  const slideIndex = slides.findIndex(r => r.id === firstSlides.id);
  if (slideIndex > 0) {
    return true;
  }
  return false;
};

export type RoundCategory = 'response' | 'collaboration' | 'results' | 'slide';

export const getRoundCategory = (type?: RoundType): RoundCategory | undefined => {
  if (!type) return 'response';
  switch (type) {
    case 'answers':
    case 'aiAssessedAnswers':
    case 'aiAssessedImageAnswers':
    case 'canvas':
    case 'ideas':
    case 'poll':
    case 'wordcloud':
    case 'groupmaker':
    case 'questions':
    case 'videos':
      return 'response';
    case 'voting':
      return 'collaboration';
    case 'podium':
    case 'responsegrid':
      return 'results';
    case 'slide':
      return 'slide';
  }
};

const INDEX_COLORS = [
  theme.colors.orange,
  theme.colors.green,
  theme.colors.purple,
  theme.colors.blue,
  theme.colors.red,
  theme.colors.yellow,
  theme.colors.greyLight, //TODO: This color is not visible on background beige.
  theme.colors.aqua,
  theme.colors.black,
  theme.colors.pink,
];
export const getIndexToColor = (index?: number) => {
  if (index === undefined) return '';
  if (index > INDEX_COLORS.length - 1) return INDEX_COLORS[0];
  return INDEX_COLORS[index];
};

export function colorToRGBA(color: string, alpha: string) {
  if (color[0] === '#') {
    const r = parseInt(color.slice(1, 3), 16),
      g = parseInt(color.slice(3, 5), 16),
      b = parseInt(color.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }
  if (color.slice(0, 4) === 'rgb(') {
    let new_col = color.replace(/rgb/i, 'rgba');
    new_col = new_col.replace(/\)/i, `,${alpha})`);
    return new_col;
  }
  if (color.slice(0, 4) === 'rgba') {
    const new_col = color.replace(/[\d\.]+\)$/g, `${alpha})`);
    return new_col;
  }
  return color;
}

export const hasSlideMedia = (type?: SlideType) => {
  if (!type) return false;
  switch (type) {
    case 'fullscreen_media':
    case 'image':
      return true;
    default:
      return false;
  }
};

export const creatorSlideTypePlaceholders = (t: TFunction<'Host'>, type?: SlideType) => {
  if (!type) return '';
  if (type === 'header') return t('writeTitle', 'e.g. write your title');
  if (type === 'bullets')
    return t('addBullet', 'e.g. Add some bullets to aid your activity');
  if (type === 'image') return t('needAImageTitle', 'e.g. need a title for the image?');
  return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const catchHandler = (error: any) => {
  // eslint-disable-next-line no-console
  console.error(error);
  newRelicNoticeError(error, 'Error caught in catchHandler, roundUtils.tsx');

  return empty();
};

export type APIState = {
  isLoading: boolean;
  error?: string;
};

export const DEFAULT_API_STATE: APIState = {
  isLoading: false,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const errorAction = <T extends Function>(action: T) => (error: string) =>
  action({ error });

export function findRoundsByReferencesReverse(startRoundId: string, rounds: Round[]) {
  const startRound = rounds.find(r => r.id === startRoundId);
  if (!startRound) {
    return [];
  }

  const tempRounds = [startRound];

  while (tempRounds.length <= 3) {
    const nextRound = rounds.find(r => r.dataReferenceRoundId === tempRounds[0].id);
    if (nextRound) {
      tempRounds.unshift(nextRound);
    } else {
      break;
    }
  }
  return tempRounds.reverse();
}

export function listRoundsByReferences(startRoundId: string, rounds: Round[]) {
  const startRound = rounds.find(r => r.id === startRoundId);

  if (!startRound) {
    return [];
  }

  let dataReferenceRoundId = startRound.dataReferenceRoundId;
  const tempRounds = [startRound] as Round[];
  while (dataReferenceRoundId) {
    // eslint-disable-next-line no-loop-func
    const relatedRound = rounds.find(r => r.id === dataReferenceRoundId);
    if (relatedRound) {
      tempRounds.unshift(relatedRound);
      dataReferenceRoundId = relatedRound.dataReferenceRoundId;
    } else {
      dataReferenceRoundId = undefined;
    }
  }
  return tempRounds;
}

export function findRelatedRounds(startRoundId: string, rounds: Round[]) {
  const referenceRounds = findRelatedRoundsWithoutIndex(startRoundId, rounds);

  return referenceRounds.map(r => ({
    ...r,
    index: rounds.findIndex(round => round.id === r.id) + 1,
  }));
}

export function findRelatedRoundsWithoutIndex(startRoundId: string, rounds: Round[]) {
  const reverseRounds = findRoundsByReferencesReverse(startRoundId, rounds);
  if (reverseRounds.length === 0) {
    return [];
  }
  const last = reverseRounds[reverseRounds.length - 1];

  const referenceRounds = listRoundsByReferences(last.id, rounds);
  return referenceRounds;
}

export function generateUniqueId() {
  return Math.floor(Math.random() * Date.now());
}

export function areAllRoundsValid(rounds: Round[]) {
  if (rounds.length === 0) return false;
  return true;
}

export const generateRoundOptionColor = (existingColors: string[]) => {
  const availableColors = INDEX_COLORS.filter(color => !existingColors.includes(color));

  if (availableColors.length === 0) {
    throw new Error('Not any more colors');
  }
  return availableColors[0];
};

export const generateDefaultRoundOptions = (count: number) => {
  return new Array(count)
    .fill(0)
    .reduce<string[]>(acc => [...acc, generateRoundOptionColor(acc)], [])
    .map(
      color =>
        ({
          id: generateUniqueId().toString(),
          displayName: ``,
          color,
        } as RoundOptions),
    );
};

export const hasSlideTitle = (type?: SlideType) => {
  if (!type) return false;
  switch (type) {
    case 'bullets':
    case 'header':
    case 'image':
      return true;
    default:
      return false;
  }
};

export const hasVoting = (type?: string) => {
  if (!type) return false;
  switch (type) {
    case 'canvas':
    case 'answers':
    case 'questions':
      return true;
    default:
      return false;
  }
};

export const wordcloudExampleData = {
  nodes: [
    {
      id: 'norway',
      label: 'Norway',
      count: 10,
      color: theme.colors.orange,
    },
    {
      id: 'bergen',
      label: 'Bergen',
      count: 1,
      color: 'blue',
    },
    {
      id: 'oslo',
      label: 'Oslo',
      count: 8,
      color: 'green',
    },
    {
      id: 'rain',
      label: 'Rain',
      count: 5,
      color: 'brown',
    },
    {
      id: 'fjord',
      count: 4,
      label: 'Fjord',
      color: theme.colors.blueDark,
    },
    {
      id: 'bunad',
      count: 3,
      label: 'Bunad',
      color: theme.colors.green,
    },
    {
      id: 'oil',
      count: 7,
      label: 'Oil',
      color: 'black',
    },
    {
      id: 'emotionless',
      count: 2,
      label: 'Emotionless',
      color: 'crimson',
    },
    {
      id: 'polite',
      count: 2,
      label: 'Polite',
      color: theme.colors.purple,
    },
    {
      id: 'snow',
      count: 4,
      label: 'Snow',
      color: theme.colors.feideBlue,
    },
    {
      id: 'rich',
      count: 3,
      label: 'Rich',
      color: 'darkred',
    },
  ],
};

// filters our podium, voting, reponsegrid for
export const getAggregatedSlidesAndActivities = (rounds: Round[]): Round[] =>
  rounds.filter(
    r => r.type !== 'voting' && r.type !== 'podium' && r.type !== 'responsegrid',
  );

export const SLIDE_COLORS = [
  '#282F33',
  '#545454',
  '#727272',
  '#A5A5A5',
  '#D9D9D9',
  '#FFFFFF',
  '#C61608',
  '#EC635E',
  '#ED70C0',
  '#BF71E0',
  '#8555F7',
  '#5622E2',
  '#43969D',
  '#57BFC8',
  '#81DEE3',
  '#5FB3F9',
  '#5870F7',
  '#1D49A7',
  '#139982',
  '#94D769',
  '#CDE176',
  '#FAE070',
  '#F5BF6B',
  '#F1975C',
];
export const NEWCREATOR_SLIDE_COLORS = [
  '#D9D9D9',
  '#139982',
  '#C61608',
  '#F1975C',
  '#FAE070',
  '#1D49A7',
  '#8555F7',
  '#282F33',
];

export const parseSeconds = (inputSeconds?: number | null): string => {
  if (!inputSeconds) return '-';
  if (inputSeconds === 0) return '-';
  const seconds = Math.floor(inputSeconds);
  if (seconds < 10) return `${seconds}`;
  if (seconds < 60) return `0:${seconds}`;
  const rest = seconds % 60;
  return `${Math.floor(seconds / 60)}:${rest > 9 ? rest : `0${rest}`}`;
};

export const moveRound = ({
  fromRoundId,
  rounds,
  toRoundId,
  before,
}: {
  fromRoundId: string;
  toRoundId: string;
  rounds: Round[];
  before: boolean;
}) => {
  // TODO - make this more performant on large arrays
  // TODO before we release custom slides - make unit tests for this function
  if (rounds.length === 0) return [];
  const relatedRounds = findRelatedRoundsWithoutIndex(fromRoundId, rounds);
  const relatedRoundIds = relatedRounds.map(r => r.id);
  const newArray = rounds.filter(r => !relatedRoundIds.includes(r.id));

  const destinationRelatedRounds = findRelatedRoundsWithoutIndex(toRoundId, rounds);

  // could use .at but we dont have polyfill yet
  const destinationRoundId = before
    ? destinationRelatedRounds[0]?.id
    : destinationRelatedRounds[destinationRelatedRounds.length - 1]?.id;

  const destinationRoundIndex = newArray.findIndex(r => r.id === destinationRoundId);
  const newIndex = before ? destinationRoundIndex : destinationRoundIndex + 1;
  newArray.splice(newIndex, 0, ...relatedRounds);
  return newArray;
};

export const createNewEmptySlide = (type: SlideType = 'header'): Round => {
  return {
    id: generateUniqueId().toString(),
    type: 'slide',
    content: {
      title: null,
      type,
      subtitle: null,
      bullets: null,
    },
    title: null,
  };
};

export const isEmptySlide = (round: Round): boolean => {
  try {
    if (
      round.type !== 'slide' &&
      round.title === null &&
      (round.media === null || round.media === undefined) &&
      (round.backgroundMedia === null || round.backgroundMedia === undefined)
    ) {
      const hasPolloptions = round.roundOptions?.some(
        option => option.displayName?.length > 0,
      );
      if (round.type === 'poll' && hasPolloptions) return false;
      return true;
    }

    if (
      round.type === 'slide' &&
      round.content?.title === null &&
      round.content?.subtitle === null &&
      (round.backgroundMedia === null || round.backgroundMedia === undefined)
    ) {
      const cannotHaveMedia =
        round.content.type === 'header' || round.content.type === 'header_text';

      if (cannotHaveMedia) {
        return true;
      }

      if (round.media === null || round.media === undefined) {
        return true;
      }
      return false;
    }

    return false;
  } catch (e) {
    /* If we somehow fuck up this logic, we just make a new slide */
    return false;
  }
};

export const createEmptyWordcloudRounds = (): Round[] => {
  const newRounds: Round[] = [
    {
      id: generateUniqueId().toString(),
      type: 'wordcloud' as RoundType,
      dataReferenceRoundId: null,
      title: null,
      durationSeconds: 60,
      durationSecondsConfig: 60,
      responseLimit: 3,
    },
  ];
  return newRounds;
};

export const createEmptyPollRounds = (): Round[] => {
  const newRounds: Round[] = [
    {
      id: generateUniqueId().toString(),
      type: 'poll' as RoundType,
      dataReferenceRoundId: null,
      title: null,
      durationSeconds: 20,
      durationSecondsConfig: 20,
      roundOptions: generateDefaultRoundOptions(3),
    },
  ];
  return newRounds;
};

export const defaultPersonalisedFeedbackMetadata = {
  feedbackVariation: 'comprehensionFeedback',
};

export const createEmptyPersonalisedFeedbackRounds = (): Round[] => {
  const uniqueId = generateUniqueId().toString();
  const uniqueIdResponse = generateUniqueId().toString();

  const newRounds: Round[] = [
    {
      id: uniqueId,
      type: 'aiAssessedAnswers' as RoundType,
      title: null,
      metadata: defaultPersonalisedFeedbackMetadata,
      dataReferenceRoundId: null,
      durationSeconds: 300,
      durationSecondsConfig: 300,
      responseLimit: 1,
      responseCharacterLimit: 1000,
    },
    {
      id: uniqueIdResponse,
      type: 'responsegrid' as RoundType,
      dataReferenceRoundId: uniqueId,
      title: null,
      durationSeconds: null,
      durationSecondsConfig: null,
    },
  ];
  return newRounds;
};

export const createEmptyDrawingRounds = (t: TFunction): Round[] => {
  const threeUniqueIds = [0, 0, 0].map(() => generateUniqueId().toString());
  const newRounds: Round[] = [
    {
      id: threeUniqueIds[0],
      type: 'canvas' as RoundType,
      dataReferenceRoundId: null,
      title: null,
      durationSeconds: 60 * 5,
      durationSecondsConfig: 60 * 5,
      responseLimit: 1,
      media: null,
    },
    {
      id: threeUniqueIds[1],
      type: 'voting' as RoundType,
      dataReferenceRoundId: threeUniqueIds[0],
      title: t('Most creative drawing'),
      durationSeconds: 90,
      durationSecondsConfig: 90,
    },
    {
      id: threeUniqueIds[2],
      type: 'podium' as RoundType,
      dataReferenceRoundId: threeUniqueIds[1],
      title: null,
      durationSeconds: null,
      durationSecondsConfig: null,
    },
  ];
  return newRounds;
};

export const createEmptyOpenQuestionRounds = (t: TFunction): Round[] => {
  const threeUniqueIds = [0, 0, 0].map(() => generateUniqueId().toString());
  const newRounds: Round[] = [
    {
      id: threeUniqueIds[0],
      type: 'answers',
      dataReferenceRoundId: null,
      title: null,
      responseLimit: 3,
      durationSeconds: 60,
      durationSecondsConfig: 60,
    },
    {
      id: threeUniqueIds[1],
      type: 'voting' as RoundType,
      dataReferenceRoundId: threeUniqueIds[0],
      title: `${t('Most relevant')} ${toSubjects(t, 'answers')}`,
      durationSeconds: 90,
      durationSecondsConfig: 60,
    },
    {
      id: threeUniqueIds[2],
      type: 'podium' as RoundType,
      dataReferenceRoundId: threeUniqueIds[1],
      durationSeconds: null,
      durationSecondsConfig: null,
      title: null,
    },
  ];
  return newRounds;
};
